.provider-detail-page {


  .provider-detail-page___sub-header {
    .provider-profile {
      --padding-top: 10px;
      --padding-bottom: 10px;

      ion-avatar {
        height: 80px;
        width: 80px;
      }

      .provider-profile___content {
        ion-label.name {
          font-weight: 500;
        }

        .distance, .team, .rating {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          font-size: 12px;
          color: #1B4170;

          ion-icon {
            margin-right: 5px;
            font-size: 16px;
          }
        }

        .team{

        }

        .rating {}
      }

    }

    ion-item.provider-detail {
      --inner-padding-top: 10px;
      --inner-padding-bottom: 10px;
      // --inner-padding-start: 20px;
      // --inner-padding-end: 0;
  
      .pending-job-number{
  
      }
      .completed-job-number {
        width: 100%;
  
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .contact-provider {
        a {
          text-decoration: none;
        }
        ion-chip {
          --background: var(--ion-color-secondary-tint);
          padding-left: 15px;
          color: var(--ion-color-secondary-contrast);
          ion-icon {
            color: var(--ion-color-secondary-contrast);
          }
        }
  
      }
    }
  }

  .provider-detail-page___content {
    ion-list {
      ion-item.job {
        --inner-padding-top: 15px;
        --inner-padding-bottom: 15px;

        ion-chip {
          font-size: 10px;
          font-weight: 500;

          &.pending_start {
            background: #F1701B;
            color: white;
          }
  
          &.pending_completion {
            background: #18ABCC;
            color: white;
          }
          &.completed {
            background: #21BA45;
            color: white;
          }
          &.cancel {
            background: #D0021B;
            color: white;
          }
        }
      }
    }
  }

  .provider-detail-page___footer {
    // padding: 25px;
    ion-toolbar {
      padding: 15px;
    }
  }

  ion-backdrop {
    opacity: 0.5;
    z-index: 10;
  }
}