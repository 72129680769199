.img-slide__container {
  background-color: var(--ion-color-dark);
  height: 100%;

  .img-slide__pagination-chip {
    --background: rgba(82, 82, 82, 0.65);
    --color: var(--ion-color-light);
    width: 68px;
    --border-radius: 32px;
  }
}
