.form-container {
  .otp-phone-input {
    margin-right: 2px;
    flex-grow: 1;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0px 5px 5px 0px !important;
    font-size: 18px !important;
    height: 100% !important;
    font-family: kanit;
    padding-left: 10px !important;
  }
  .send-otp-small-paragraph-1 {
    margin-bottom: 12px;
  }

  .send-otp-small-paragraph-2 {
    margin-top: 6px;
    margin-bottom: 12px;
  }

  .input-container {
    display: flex;
    height: 53px;
  }

  .country-box {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    height: 100%;
    margin-left: 2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    img {
      margin-left: 10px;
      margin-right: 10px;
      height: 25px;
    }

    .country-number {
      margin-right: 5px;
    }
  }
}
