.job-rating-page--content-wrapper {
  background-color: var(--ion-color-primary);
  height: 100%;
  text-align: center;
  padding-top: 20px;

  .title {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
  }
  .qrcode-wrapper {
    height: auto;
    margin: 0 auto;
    max-width: 150px;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    padding: 8px;

  }

  .label-value {
    font-size: 13px;
    color: #fff;
    display: block;
    margin-top: 30px;
  }
  .label-type {
    font-size: 18px;
    color: #fff;
    display: block;
  }
  .label-description {
    font-size: 14px;
    color: #fff;
    display: block;
    padding-top: 26px;
    max-width: 240px;
    margin: 0 auto;
  }


}
