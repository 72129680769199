.sign-up-form-container {
  padding: 10px;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: #f7f7f7;
}

.sign-up-page {
  .grid-container {
    min-height: 100%;
    display: flex;
    align-items: top;
    max-width: 400px;
    padding-top: 10px;

    .line-login-button {
      --background: #00c300;
      --background-activated: #00b300;
      --background-focused: #00e000;
      --background-hover: #00e000;
      --padding-start: 0px;
      --padding-end: 30px;

      height: 40px;
      margin-bottom: 16px;

      .line-login-button__icon {
        height: 100%;
        border-right: 1px solid #00b300;
      }

      &:hover .line-login-button__icon {
        border-right: 1px solid #00c900;
      }

      .line-login-button__text {
        width: 100%;
      }
    }

    .facebook-sign-in-button {
      --background: #4968ad;
      height: 40px;
      text-align: center;
      margin-bottom: 16px;
      --padding-end: 30px;

      .facebook-sign-in-button__icon {
        font-size: 32px;
      }

      .facebook-sign-in-button__text {
        width: 100%;
      }
    }

    .apple-sign-in-button {
      --background: black;
      --border-radius: 4px;
      --border-width: 1px;
      --border-style: solid;
      --border-color: white;
      --padding-start: 0;
      --padding-end: 30px;
      --background-activated: #black;
      height: 40px;
      text-align: center;
      margin-bottom: 16px;

      .apple-sign-in-button__icon {
        height: 100%;
        font-size: 32px;
      }

      .apple-sign-in-button__text {
        width: 100%;
        color: white;
        // font-size: 19px;
        // font-family: 'Helvetica';
      }
    }

    .button-divider {
      display: flex;
      padding: 0 8px;
      margin: 5px 0;

      .text {
        margin: 0 10px;
      }

      .line {
        flex: 25%;
        border-bottom: 1px solid #989aa2;
        transform: translateY(-45%);
      }
    }
  }
}
