.modal-container {
  --height: 25%;
  align-items: flex-end;
}

.modal-content {
  margin: 3%;
  padding: 5%;
}

.text-android {
  font-size: 1em;
  font-weight: lighter;
}

.text-ios {
    font-size: 1.2em;
    font-weight: lighter;
  }
  
