.field-label {
  display: block;
  margin-bottom: 6px;
}

.error-message-placeholder {
  p {
    line-height: 16px;
    opacity: 0;
    margin: 0;
  }
}

ion-icon {
  &.verified {
    color: #00d810;
  }
}
.base-field {
  &.label{
    &.row {
      display: flex;
      justify-content: space-between;

      span.edit-phone {
        color: #2D95F4;
        text-decoration: underline;
      }
    }
  }
}
