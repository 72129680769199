.wallet-transaction__list-item {
  --padding-top: 16px;
  --padding-end: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;

  --inner-padding-top: 0;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;

  .wallet-transaction__inner-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & h3 {
      margin: 0 0 8px 0;
      font-size: 18px;
      font-weight: 400;
    }

    & p {
      margin: 0;
      font-weight: 300;
    }

    & > div:nth-child(1) {
      flex: 1;

      & > p {
        font-size: 15px;
      }

      .number {
        & > p {
          font-weight: 500;
        }
      }
    }

    & > div:nth-child(2) {
      text-align: right;
      width: 104px;

      & > h3 {
        &.wt-amount--orange {
          color: #F5A623;
        }

        &.wt-amount--green {
          color: var(--ion-color-success);

          &::before {
            content: '+ ';
          }
        }

        &.wt-amount--red {
          color: #D0021B;

          &::before {
            content: '- ';
          }
        }

        &.wt-amount--grey {
          color: var(--ion-color-medium);
          text-decoration: line-through;
        }
      }

      & > p {
        font-size: 13px;
        color: var(--ion-color-success);

        &.wt__has-fee {
          color: var(--ion-color-danger);
        }
      }

      & > ion-icon {
        &.wt__active {
          transform: rotate(180deg);
        }
      }


    }

    & > div:nth-child(3) {
      flex: 100%;

      &.wt__active {
        display: block;
      }

      &.wt__inactive {
        display: none;
      }

      & > .wt-details__container {
        & > p, div {
          font-size: 13px;
          font-weight: 300;
          color: #828282;
        }

        & > .wt-details__description {
          font-size: 15px;
        }

        & > .wt-details__remark, .wt-details__remark--danger {
          margin-left: 8px;
        }

        & > .wt-details__remark--danger {
          color: var(--ion-color-danger);
        }

        & > .wt-details__remark {
          & > .wt-details__remark--bank {
            display: flex;
            align-items: center;
            padding-top: 4px;

            & > img {
              margin-right: 8px;
            }
          }

          & > .wt-details__remark--account--name {
            padding: 4px 0;
            line-height: 23px;
          }
        }
      }
    }
  }
}
