.provider-header-container {
  margin-left: 6px;
  margin-right: 6px;
}

.provider-header-display-name {
    color: #47525D;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    IonText {
      margin: 0;
      padding: 0;
    }
  }
  .provider-header-rating {
    height: 16px;
    width: 16px;
    margin-top: 5px;
    margin-left: 7px;
    margin-right: 7px;
  }
  .provider-header-img-col {
    display: flex;
    margin-top: 6px;

    justify-content: flex-end;
    align-items: center;
    IonAvatar {
      width: 55px;
      height: 55px;
    }
  }
