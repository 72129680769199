.date-range-picker-label {
  padding-bottom: 8px;
}

.DateRangePickerInput {
  input {
    text-align: center;
  }
}

.DateRangePickerInput input {
  font-size: 16px;
}

.DateRangePickerInput_arrow {
  line-height: 5px;
}

.input-time-range {
  input.native-input.sc-ion-input-ios {
    opacity: 1 !important;
  }
}
