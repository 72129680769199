@import 'src/theme/variables.scss';

.segment-container {
  background: white;

  -webkit-box-shadow: 0px 2px 4px 0px rgba(54,54,54,0.3);
  -moz-box-shadow: 0px 2px 4px 0px rgba(54,54,54,0.3);
  box-shadow: 0px 2px 4px 0px rgba(54,54,54,0.3);

  margin-bottom: 16px;

  .segment-title {
    padding: 16px;
    border-bottom: 1px solid #CECECE;

    .title {
      font-size: $title2;
      margin-right: 8px;
    }
  }

  .segment-content {
    padding: 16px;
  }
}
