.notification-wrapper-modal {
  --min-height: 220px;
  --height: fit-content;
  --border-radius: 16px;
  padding: 16px;

  &.has-image {
    --min-height: 475px;
  }

  .wrapper-content {
    padding: 16px;
  }

  .notification-image {
    margin-top: 40px;
  }

  .description-wrapper-content {
    text-align: center;
  }

  .wrapper-footer {
    padding: 0 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}