.form-container {
  --ion-grid-padding: 0;

  .forget-password-row {
    justify-content: flex-end;
  }

  .sign-in-button-col {
    --ion-grid-column-padding: 0;
  }
}