.referral-card-container {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 2px solid rgba(0,0,0,0.05);
  margin-bottom: 0px;
}

.referral-card-avatar {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 6px;

  .referral-card-avatar-img {
    width: 69px;
    height: 69px;
  }
}

.referral-card-name-data-col {
  margin-left: 20px;
}
.referral-card-name-text {
  margin-top: 0px;
  font-size: 16px;
  margin-bottom: 4px;
  color: #47525D
}

.referral-card-info-row {
  font-size: 16px;
  margin-bottom: 2px;

  .registering {
    color: #F5A623
  }

  .approved {
    color: #2D95F4;
  }
  .completed {
    color: #18CB88
  }

}


.referral-card-date-text {
  font-size: 13px;
  color: #7E7E7E;
}
