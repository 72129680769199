.job-completion-page--content-wrapper {
  padding: 16px;

  .job-completion-page--content {
    border: 1px solid var(--ion-color-medium);
    border-radius: 12px;
    margin-top: 16px;
    background-color: white;

    .tap-sign-text {
      display: block;
      text-align: center;
      margin-top: 24px;
    }

    .tap-sign-icon {
      height: 85px;
      display: block;
      margin: 8px auto 24px auto;
    }
  }
}


.signature-pad-modal--close-button {
  --padding-start: 0px !important;
  margin-left: -4px !important;
}

.signature-canvas-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-light);

  .signature-canvas-element {
    border-radius: 12px;
  }
}

.signature-pad-modal--action {
  width: 100% !important;
  margin: 8px;
}

.customer-not-present {
  margin-bottom: 8px;
}

.signature-pad-modal--root {
  padding-top: env(--ion-safe-area-top);
  background-color: var(--ion-color-primary);
  margin-bottom: env(--ion-safe-area-bottom);
}
