.upcoming-job-container {
  width: 100vw;
  box-shadow: 1px 2px 4px 0 rgba(182,182,182,0.5);
  background-color: #FFF;

  .upcoming-job-detail {
    --padding-bottom: 1rem;
    --padding-end: 1rem;
    --padding-start: 1rem;
    --padding-top: 1rem;

    ion-label {
      font-weight: 300;
    }

    .upcoming-job-detail__show-button {
      display: inline-flex;
      float: right;

      ion-text {
        padding-right: 4px;
      }

      ion-icon {
        height: 14px;
        padding: 6px 0 3px 0;
      }
    }

    .upcoming-job-detail__description {
      --ion-grid-padding: 0;

      ion-col {
        --ion-grid-column-padding: 0;
      }

      .upcoming-job-detail__service-name {
        font-size: 18px;
        padding-top: 18px;
      }

      .upcoming-job-detail__label {
        width: 100%;
        font-weight: 300;
        padding-top: 8px;
        display: flex;
        flex-direction: row;

        .clock-icon {
          width: 12px;
          height: 24px;
          margin: 0;
          padding: 6px 0;
        }

        .marker-icon {
          width: 12px;
          height: 24px;
          margin: 0;
          padding: 4px 0;
        }
  
        .upcoming-job-detail__description-start-date, .upcoming-job-detail__description-address {
          margin-left: 8px;
        }
  
        .upcoming-job-detail__description-start-time {
          margin-left: 20px;
        }
      }

      .upcoming-job-detail__hide-button {
        padding-top: 8px;
        float: right;
  
        ion-text {
          line-height: 24px;
          padding-right: 4px;
          vertical-align: middle;
        }

        ion-icon {
          transform: rotate(180deg);
          vertical-align: middle;
        }
      }
    }
  }
}
