.group-header {
  height: 39px;

  .group-label {
    margin: 10px auto;
  }
}

.main-list-item {
  margin-left: -10px;
  --padding-top: 16px;
  --inner-padding-end: 16px;
  border: none;
}

