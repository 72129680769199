.info-button-container {
  min-height: 90px;
  width:100%;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .info-button-heading {
    font-size: 20px;
  }

  .info-button-row {
    margin-left: 24px;
    color: white;
    .info-button-content {
      font-size: 16px;
    }
    .info-button-data {
      font-size: 16px;
    }

  }
}
