.work-page-header {
  position: relative;
  background-color: var(--ion-color-primary);

  ion-grid {
    ion-row:first-of-type {
      ion-col {
        padding: 25px 15px 0px;
        // --ion-grid-column-padding-top: 22px;
        // --ion-grid-column-padding-right: 8px;
        // --ion-grid-column-padding-left: 8px;
      }
    }

    ion-row.searchbar-row {
      ion-searchbar {
        padding-top: 0;
        --border-radius: 25px;
        ion-icon {
          right: 16px !important;
          left: auto !important;
        }

        input {
          padding-inline-start: 20px;
        }

        .searchbar-clear-button.sc-ion-searchbar-md {
          right: 35px;
        }
      }
    }
  }

  .header-label {
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .avatar-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;

    width: 60px;

    ion-avatar {
      height: 57px;
      width: 57px;
    }
  }
}

.work-page-content {
  max-height: calc(100% - 187.3px);
}