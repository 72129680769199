.camera-input-button__container {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: calc((100% - 24px) / 3);
    margin: 10px 0;
    height: 110px;
  }

  & > *:nth-child(3n+2) {
    margin-left: 12px;
    margin-right: 12px;
  }

  .camera-input-button {
    border: 2px dashed var(--ion-color-medium);
    border-radius: 12px;
  }

  .photo-thumbnail__container {
    border-radius: 12px;
    position: relative;
    overflow-y: hidden;

    & > img {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    }

    .photo-thumbnail__remove-button {
      --padding-bottom: 0;
      --padding-end: 0;
      --padding-start: 0;
      --padding-top: 0;

      position: absolute;
      top: 2px;
      right: 1px;
      opacity: 0.8;
    }
  }
}

.photo-lightbox-modal {
  --background: rgba(0, 0, 0, 0.8);

  .photo-lightbox-modal__inner-wrapper {
    text-align: right;
  }
}
