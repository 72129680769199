.home-page {
  --background: linear-gradient(
    296.18deg,
     #5B8AC3 0%,
     #2F588C 53.22%,
     #1B4170 100%
    );

  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 6px;
  --padding-end: 6px;

  .grid-container {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 400px;

    .workforce-logo {
      margin: 0 20px 24px 20px;
    }

    .button-divider {
      display: flex;
      padding: 0 8px;
      margin: 16px 0;

      .text {
        margin: 0 10px;
      }

      .line {
        flex: 30%;
        border-bottom: 1px solid #f4f5f8;
        transform: translateY(-45%);
      }
    }
  }
}
