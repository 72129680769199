.my-available-distance-page {
  .page-content-container {
    background-color: #ffffff;
    background-image: none;
  }

  .wrapper {
    padding: 16px;
  }

  .label {
    color: #47525D;
    font-size: 18px;
    padding-bottom: 16px;
  }
}
