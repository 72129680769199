.team-job-comment-modal {
  // padding-top: var(--ion-safe-area-top);
  --backdrop-opacity: 1 !important;

  ion-backdrop {
    // opacity: 1;
    background: rgba(255, 255, 255, 1);
  }

  ion-content {
    ion-list.job-comment-list {
      .job-comment-item {
        ion-avatar {
          margin-top: 15px;
          margin-bottom: auto;
        }
      }
    }
  }

  ion-footer {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(
      --ion-toolbar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2)))
    );
    box-sizing: border-box;
    // padding-bottom: var(--ion-safe-area-bottom);
    padding-bottom: 10px;
    ion-grid {
      ion-row {
        min-height: 80px;
        ion-col.comment-column {
          ion-textarea {
            height: 100%;
            div.textarea-wrapper {
              height: inherit;
              textarea {
                height: inherit;
              }
            }
          }
        }
        ion-col.icon-column {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin: 0 20px 0 auto;
          ion-icon {
            font-size: 18px;
            width: 25px;
            height: 25px;
            color: #47515d;
            margin-top: 5px;

            &.navigate-icon {
              font-size: 25px;
              color: #2168c0;
              width: 35px;
              height: 35px;
              margin-top: auto;
            }
          }
        }
      }
    }

    &.expanded {
      height: 100%;

      ion-grid {
        height: inherit;
        ion-row {
          height: 100%;
          ion-col.comment-column {
            ion-textarea {
              height: 100%;
              div.textarea-wrapper {
                height: inherit;
                textarea {
                  height: inherit;
                }
              }
            }
          }
          ion-col.icon-column {
            justify-content: flex-start;

            ion-icon.navigate-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}
