.refer-friend-pending-page-container {
  min-height: 100%;
}

.refer-friend-pending-page-header {
  margin-bottom: 17px;
}

.refer-friend-pending-page-content-row {
  margin-left: 16px;
  margin-right: 16px;
  justify-content: center;


  .refer-friend-pending-page-content-text {
    font-size: 18px;
  }
}
.refer-friend-pending-page-btn-tab-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: -11px;

}
.refer-friend-pending-footer {
  width: 100%;
  
  .refer-friend-pending-page-btn {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
}
