.job-report-pane {
  background-color: white;
  padding: 16px;
  min-height: 100%;

  .not-for-customer-text {
    margin-bottom: 14px;
    display: block;
  }
}
