@import 'src/theme/variables.scss';

.no-padding-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

.job-details__item {
  margin-bottom: 8px;

  .job-details__item-title-subtitle {
    margin: 0;

    & > * {
      display: block;
    }

    & > *:first-child {
      font-size: $caption;
      font-weight: $light;
      margin-bottom: 8px;
    }

    & > *:last-child {
      font-size: $title2;
    }
  }

  .job-details-service-icon {
    width: 60px;
    height: 60px;
    margin: 0;
  }
}

.job-details__order {
  --min-height: 0px;
  margin-bottom: 8px;

  & > *:first-child {
    margin: 0 8px 0 0;
  }
}

.text-red {
  color: rgb(255, 0, 0);
}
