@import 'src/theme/variables.scss';

.forgot-password-page-core-layout {
  background-color: #f7f7f7;

  .grid-container {
    margin: 0 8px;
    .text-content {
      padding: 16px;

      p {
        font-size: $body;
        line-height: 25px;
        margin: 0;
      }
    }
  }
}
