@import 'src/theme/variables.scss';

.refer-friend-page-container {
  background: white;
}
.refer-friend-header-row {
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  .refer-friend-header-image {
    height: 176px;
    min-width: 290px;
  }
}

.refer-friend-content-container {
  margin-right: 16px;
  margin-left: 16px;
  color: #47525d;


  .refer-friend-content-header-row {
    margin-bottom: 16px;
    justify-content: center;
    .refer-friend-content-header-text {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
    }
  }
  .refer-friend-content-row {
    margin-bottom: 32px;
    .refer-friend-content-text {
      font-size: 16px;
    }
  }
}
.refer-friend-info-button-one {
  margin-bottom: 16px;
}

.refer-friend-universal-url-row {
  width: 100%;

  .refer-friend-universal-url-btn {
    width: 100%
  }
}

.refer-friend-footer {
  width: 100%;
  
  .refer-friend-universal-url-btn {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
}
