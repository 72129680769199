.sign-up-referral-container{
  margin-left: 16px;
  margin-right: 16px;
  background: white;
  height: 100%;

  .sign-up-referral-content-container {
    background: white;
    height: 100%;
    .sign-up-referral-header-row {
      margin-bottom: 24px;
      justify-content: center;
    }

    .sign-up-referral-header-text-row {
      justify-content: center;
      margin-bottom: 16px;

      .sign-up-referral-header-text {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
      }
    }
    .sign-up-referral-content-row {
      margin-bottom: 24px;
      justify-content: center;
      .sign-up-referral-content-text {
        font-size: 16px;

      }
    }    
    .sign-up-referral-sign-in-with-email-row{
      justify-content: center;
      margin-bottom: 13px;

      .line {
        flex: 10%;
        border-bottom: 1px solid #989aa2;
        transform: translateY(-45%);
      }
      .sign-up-referral-sign-in-with-email-text {
        font-size: 13px;
        width: 100;
        color: #47525D;
        margin-left: 5px;
        margin-right: 5px;

      }
    }
  }
}
