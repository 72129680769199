.pending-message-container {
  background-color: #1b4170;
  color: #fff;

  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%;

  .header-container {
    margin-bottom: 24px;
  }

  .img-container {
    margin-bottom: 24px;
  }

  .spacer {
    margin-bottom: 4px;
  }
  .we-will-contact-you {
    margin-bottom: 8px;
  }
  .line-add-friend-button {
    --background: #00c300;
    --background-activated: #00b300;
    --background-focused: #00e000;
    --background-hover: #00e000;
    --padding-end: 2px;
    --padding-start: 2px;

    max-width: 150px;

    margin-bottom: 12px;

    & img {
      height: 100%;
    }

    .line-add-friend-button__text {
      padding: 0 8px;
    }
  }

  .button-container {
    margin-top: auto;
  }

  .sign-up-pending-back-button {
    --background: #2d95f4;
  }
}
