.typo-paragraph {
  color: #222222;
  font-size: 16px;
  font-weight: lighter;
}
.error-paragraph {
  color: #ed3138;
}
.white {
  color: #fff;
}
