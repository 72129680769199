.group-header {
  height: 39px;

  .group-label {
    margin: 10px auto;
  }
}

.list-item {
  --padding-top: 16px;
  --inner-padding-end: 16px;
  --border-width: 0 0 0.55px 0 !important;
}
