.my-custom-class-for-label {
  width: 50px; height: 20px;
  
  border: 1px solid #eb3a44;
  border-radius: 5px;
  
  background: #fee1d7;
  color: #eb3a44;
  text-align: center;
  line-height: 20px;
  
  font-weight: bold;
  font-size: 14px;
}

[title="spot"] {
  opacity: 1 !important;
  img {
    border-radius: 50%;
  }
}

.google-map-container {
  position: relative;
  width: 100%;
  height: 100%;

  .google-map {
    width: 100%;
    height: 100%;

    .gm-style .gm-style-iw-d {
      overflow: auto !important;
    }

    .gm-ui-hover-effect {
      display: none !important;
    }

    &.originRoute {
      .gm-style-iw-c {
        padding: 8px 16px !important;
        color: #FFFFFF !important;
        background-color: #1E74D7 !important;
        box-shadow: 0 1px 2px 0 #B6B6B6, 0 2px 4px 0 #B6B6B6;
      }
    
      .gm-style-iw-t::after {
        display: block;
        background-color: #1E74D7 !important;
        background: linear-gradient(0deg, #1E74D7 0%, #1E74D7 100%);
        box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
        content: "";
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        transform: translate(-50%,-50%) rotate(-45deg);
        width: 15px;
      }
    }

    &.destinationRoute {
      .gm-style-iw-c {
        padding: 8px 16px !important;
        color: #FFFFFF !important;
        background-color: #fe2e2f !important;
        box-shadow: 0 1px 2px 0 #B6B6B6, 0 2px 4px 0 #B6B6B6;
      }
    
      .gm-style-iw-t::after {
        display: block;
        background-color: #fe2e2f !important;
        background: linear-gradient(0deg, #fe2e2f 0%, #fe2e2f 100%);
        box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
        content: "";
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        transform: translate(-50%,-50%) rotate(-45deg);
        width: 15px;
      }
    }
  }


  .google-map-marker {
    position: absolute;
    // width: 100%;
    height: 40px;
    top: -40px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #f44336;
  }

  .google-map-places-container {
    position: absolute;
    padding: 10px;
    width: 100%;

    &.hidden {
      display: none;
    }

    .google-map-places-input {
      width: 100%;
      font-size: 1rem;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;

      &:focus, &:hover {
        border: 1px solid #5b8ac3;
        outline: none;
      }
    }
  }
}
