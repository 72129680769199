@import 'src/theme/variables.scss';
.main-page {
  background: white;
  height: 100%;
  // padding-top: var(--ion-safe-area-top);
}

.main-page-safe-area-top {
  background: white;
}

.main-page-content {
  padding-top: env(--ion-safe-area-top);
}

.main-page-big-square {
  margin-top: 3px;
}
.main-page-container {
  margin-left: 8px;
  margin-right: 8px;
}
.main-page-list-header-text-row {
  margin-bottom: -7px;
}

.main-page-list-info-col {
  margin-bottom: 0px;
  .main-page-list-info-text {
    font-size: 16px;
    color: #18CB88;
  }
}
.main-page-side-margin-left {
  margin-left: 6px;
}
.main-page-side-margin-right {
  margin-right: 6px;
}
.main-page-list-link-text {
  font-size: 16px;
}
.main-page-provider-header {
  margin-left: 4px;
  margin-right: 4px;
}

.main-page-list-info-link {
  display: flex;
  justify-content: flex-end;
  margin-right: 4px;
}
.main-page-list-link-text {
  cursor: pointer;
}
.main-page-list-header-text {
  font-size: $title-md;
  font-weight: $medium;
  color: #47525D;
}

.main-page-small-square-row {
  padding-top: env(--ion-safe-area-top);
}

