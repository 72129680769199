.accordion {

  .accordion__header {
    font-size: medium;
    .label__container {
      display: flex;

      ion-label {
        margin-right: 10px;
      }
      ion-icon {

      }
    }
  }
  .accordion__content {
    overflow-y: scroll;
    // transition: max-height 0.2s ease-out;
    // border-bottom: solid 1px #c8c7cc;
    max-height: auto;
    &.expanded {
      max-height: auto;
    }
    &.closed {
      max-height: 0px;
    }
  }
}