.pop-up-calendar-card {
  position: absolute;
  z-index: 11;
  top: 42vh;
  padding: 16px;
  margin: 16px;
  font-size: 15px;
  background-color: #5B8AC3;
  color: #FFFFFF;
}

.pop-up-center-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  padding: 0;
  line-height: 23px;
}

.pop-up-close-icon {
  position: absolute;
  z-index: 12;
  margin-right: 3px;
  right: 0;
  width: 40px;
  height: 40px;
  top: 42vh;
}
