.camera-input-basic__container {
  position: relative;

  .camera-input-basic__preview {
    width: 168px;
    height: 168px;
    margin: 0 auto;
    background-color: var(--ion-color-light-tint);
  }

  .camera-input-basic__edit-button {
    position: absolute;
    bottom: 0;
    right: 29%;
    border-radius: 50%;
    font-size: 24px;
    padding: 6px;
    background-color: var(--ion-color-light-shade);
  }
}
