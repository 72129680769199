.verify-complete-grid {
  margin: 0 20px;
  .verify-complete-logo-row {
    margin-top: 12px;
  }

  .verify-complete-header-row {
    margin-bottom: 8px;
  }

  .verify-complete-lower {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .verify-complete-subheader-row {
    margin-bottom: 8px;
  }

  .verify-complete-paragraph-row {
    margin-bottom: 50px;
  }

  .verify-complete-avatar-row {
    margin-bottom: 20px;
  }

  .verify-complete-paragraph-2-row {
    margin-bottom: 12px;
  }
}
