@import 'src/theme/variables.scss';


.main-job-card-container {
  padding-bottom: 8px;
  --ion-grid-padding: 0;
  ion-col {
    --ion-grid-column-padding: 0;
  }
  .main-job-card-service-name {
    color: #47525D;
    font-weight: 500;
    margin-bottom: -3px;
  }
  .main-job-card-start-time {
    color: #7E7E7E;
  }
  .row-flex-end {
    justify-content: flex-end;
    font-family: "roboto";
    margin-top: 1px;
    .payout {
        .payout-symbol {
            font-size: 14px;
            font-weight: 600;
            font-family:'thonburi roboto';
        }
        .payout-price {
            font-size: 24px;
            font-weight: 600;
        }
    margin: 8px 1px 0 0; 
  }
  }

}
.main-job-card-service-icon{
  width: 48px;
  height: 48px;
  margin-top: 2px;
}
