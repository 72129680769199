.sign-up-page-verify-core-layout {
  .sign-up-verify-page-container {
    h4 {
      margin: 0;
      padding: 0;
    }
    h3 {
      margin: 0;
      padding: 0;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .subheader-container {
      margin-top: 20px;
      margin-bottom: 16px;
    }

    .header-container {
      margin-bottom: 2px;
    }

    .paragraph-container {
      margin-bottom: 6px;
      text-align: center;
    }

    .phone-number-container {
      margin-bottom: 20px;
    }

    .otp-form-container {
      margin-bottom: 8px;
    }

    margin: 0 22px;
  }
}
