#countdown {
  & * {
    font-weight: 500;
  }

  .coundown-message {
    font-size: 15px;
    text-align: center;
    margin: 0px;
  }

  .time-render-root {
    display: flex;
  }

  .splitter {
    display: flex;
    justify-content: center;
  }

  .time-container{
    display: flex;
    justify-content: center;
  }

  .timer-label {
    font-size: 32px;
    text-align: center;
    width: fit-content;;
    margin: 0px;
  }

  .time-text {
    font-size: 13px;
    text-align: center;
  }

  .hour-countdown {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
  }

  .minute-countdown {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
  }

  .second-countdown {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
  }
}
