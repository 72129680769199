.set-password-form-container {
  --ion-grid-padding: 0;

  p {
    margin: 0;
  }

  .password-col {
    margin-bottom: 8px;
  }

  .re-password-col {
    margin-bottom: 14px;
  }

  .paragraph-col {
    margin-bottom: 12px;
  }

  .forget-password-row {
    justify-content: flex-end;
  }

  .sign-in-button-col {
    --ion-grid-column-padding: 0;
  }
}
