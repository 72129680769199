.timer {
  padding: 0;
  .timer-row {
    padding: 0;
    color: #ed3138;
    font-size: 14px;
  }

  .reset-row {
    padding: 0;
    color: '#222222';
    font-size: 14px;

    .pre {
      margin-right: 6px;
      margin-bottom: 8px;
    }
    .after {
      font-weight: 500;
      text-decoration: underline;
      color: #2d95f4;
    }
    .disabled {
      opacity: 0.65;
    }
  }
}
