@import 'src/theme/variables.scss';

.payout-value-label-row {
  margin-bottom: 6px;
}

.payout-display-value {
  font-size: $title2;
  margin: 4px 8px 0 0;
}

.job-action-button-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message-icon {
  width: 55px;
  height: 55px;
}

.call-icon {
  width: 55px;
  height: 55px;
}
