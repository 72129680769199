@import 'src/theme/variables.scss';

.sign-in-page {
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 6px;
  --padding-end: 6px;

  h1,
  p {
    margin: 0;
  }

  .sign-in-header-section {
    display: flex;
    align-items: center;
    .header-text {
      margin-right: 4rem;
    }
  }

  .grid-container {
    height: 100%;
    display: flex;

    max-width: 400px;

    padding-top: var(--ion-safe-area-top);

    .sign-in-with-phone-number-container {
      margin-bottom: 20px;
    }

    .line-login-button {
      --background: #00c300;
      --background-activated: #00b300;
      --background-focused: #00e000;
      --background-hover: #00e000;
      --padding-start: 0px;
      --padding-end: 30px;

      height: 40px;
      margin-bottom: 12px;

      .line-login-button__icon {
        height: 100%;
        border-right: 1px solid #00b300;
      }

      &:hover .line-login-button__icon {
        border-right: 1px solid #00c900;
      }

      .line-login-button__text {
        width: 100%;
      }
    }

    .facebook-sign-in-button {
      --background: #4968ad;
      height: 40px;
      text-align: center;
      margin-bottom: 12px;
      --padding-end: 30px;

      .facebook-sign-in-button__icon {
        font-size: 32px;
      }

      .facebook-sign-in-button__text {
        width: 100%;
      }
    }

    .apple-sign-in-button {
      --border-radius: 4px;
      --border-width: 1px;
      --border-style: solid;
      --border-color: black;
      --padding-start: 0;
      --padding-end: 30px;
      --background-hover: rgb(70, 67, 67);
      --background: black;

      height: 40px;
      text-align: center;
      margin-bottom: 12px;

      .apple-sign-in-button__icon {
        height: 44px;
        width: 44px;
      }

      .apple-sign-in-button__text {
        width: 100%;
        color: white;
        // font-size: 19px;
        // font-family: 'Helvetica';
      }
    }
    .policy-paragraph {
      text-align: center;
      margin-bottom: 8px;
    }

    .sign-in-sign-up-row {
      display: flex;
      justify-content: center;
    }
    .blue-underline {
      cursor: pointer;
      color: #2d95f4;
      text-decoration: underline;
    }

    .bold {
      font-weight: 600;
    }

    .terms-and-conditions-text {
      font-size: $caption;
      display: block;
      padding: 0 10px;
    }

    .register-now-text {
      display: block;
      text-align: center;
      margin: 16px auto;
    }

    .button-divider {
      display: flex;
      padding: 0 8px;
      margin: 16px 0;

      .text {
        margin: 0 10px;
      }

      .line {
        flex: 25%;
        border-bottom: 1px solid #989aa2;
        transform: translateY(-45%);
      }
    }
  }
}

.alert-button.ion-focusable.ion-activatable.sc-ion-alert-ios:last-child.fw-normal {
  font-weight: normal;
}
