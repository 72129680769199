.my-address-page {
  padding: 16px;
  height: 100%;
  background-color: white;

  .marker-icon-image {
    width: 20px;
    position: absolute;
  }

  .person-image {
    width: 20px;
    height: 20px;
  }
}

.my-address-footer-container {
  padding: 8px;
}
