.team-provider-card {
  font-size: medium;

  border: solid 1px #5A8AC3;
  border-radius: 5px;
  padding: 10px 5px;
  margin-bottom: 3px;

  display: flex;
  align-items: flex-start;

  ion-avatar {
    height: 50px;
    width: 50px;
  }

  .provider-detail {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: space-between;
      .rating {
        // margin-right: auto;
      }
    }
    .distance {
      display: flex;
      padding-top: 5px;
      font-size: small;
      ion-icon {
        font-size: large;
        margin-right: 5px;
      }
    }
  }

  .rating {
    margin-bottom: auto;
  }
}