.bank-account-select__label {
  --inner-padding-bottom: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;

  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;

  & > ion-avatar {
    margin-right: 12px;
  }

  & > ion-label {
    & > h2 {
      font-size: 18px;
      color: var(--ion-color-secondary);
    }

    & > h3 {
      font-size: 15px;
    }
  }
}
