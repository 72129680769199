.info-box-container {
  min-height: 154px;
  max-height: 600px;
  min-width: 154px;
  margin-right: 3px;
  border-radius: 5px;
}

.info-box-big-container {
  min-height: 139px;
  min-width: 154px;
  max-height: 600px;
  border-radius: 5px;
  margin-right: 3px;
}
.info-box-content {
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: #fff;

  .info-box-top-heading {
    margin-left: 16px;
    margin-right: 16px;
    max-width: 17ch;
    margin-top: 16px;
  }
  .info-box-top-heading-big {
    margin-left: 16px;
    margin-top: 30px;
  }
  .info-box-top-data {
    margin-left: 16px;
  }
  .info-box-bottom-content {
    margin-top: 16px;
  }
  .info-box-bottom-content-big {
    margin-top: 14px;
  }
  .info-box-bottom-heading {
    margin-left: 16px;
    font-size: 13px;
  }
  .info-box-bottom-data {
    margin-left: 16px;
    font-size: 26px;
    font-family: "roboto";
    font-weight: 500;
    .info-box-bottom-data-first-char {
      font-size: 16px;
      font-weight: 700;
      font-family: "thonburi roboto";
    }
    .info-box-bottom-data-first-char-big {
      font-size: 20px;
      font-weight: 700;
      font-family: "thonburi roboto";
    }

    .info-box-big-text {
      font-size: 26px;
    }
  }
}
