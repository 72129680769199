@import 'src/theme/variables.scss';

.start-time-list {
  margin-top: 0;

  .start-time-item {
    --padding-bottom: 8px;
    --padding-top: 8px;

    .start-time__date {
      width: 40px;
      text-align: center;
      font-weight: $medium;

      & > *:first-child {
        font-size: $title2;
      }
    }

    .start_time__full-date-time {
      font-weight: $light;

      .time-available-chip {
        background-color: rgba(24,203,136,0.75);
        margin: 0;
        position: absolute;
        right: 8px;
        top: 16px;
        font-size: 10px;
        padding: 0 5px;
        height: 24px;
      }
      
      .time-available-chip-label {
        color: #FFFFFF !important;
      }
      
      .time-disabled-chip {
        background-color: #FCABB5;
        margin: 0;
        position: absolute;
        right: 8px;
        top: 16px;
        font-size: 10px;
        padding: 0 5px;
        height: 24px;
      }
      
      .time-disabled-chip-label {
        color: #D0021B !important;
      }

      
      & > *:first-child {
        margin-bottom: 4px;
      }
    }
  }
}
