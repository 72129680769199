.refer-friend-tab-button {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  flex-grow: 1;
  z-index: 20;
}
.active {
  color: #47525D;
  border-bottom: 3px solid #1B4170;
}
.inactive {
  color: #AAAAAA;
}
