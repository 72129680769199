.page-content-container {
  --background: none;
  background-image: url('/assets/img/background_service_icons.png');
  background-color: #f7f7f7;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  .page-refresher {
    z-index: 0;
  }
}
