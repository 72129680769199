.search-input-container {
  color: #47525d;
  background-color: #f7f7f7;

  .search-input-group {
    background: white;
    display: flex;
    align-items: center;
    padding: 16px 16px 8px 16px;

    .search-input {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      background-color: #fff;

      ion-input {
        --padding-start: 30px;
        border: 1px solid #fff;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0 1px 3px 0 #b6b6b6;

        &.has-focus {
          border: 1px solid #2d95f4;
        }
      }

      ion-icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 10px;
        color: #979797;
      }
    }

    .close-btn {
      padding: 0 10px;
      cursor: pointer;
    }
  }

  ul {
    margin: 0 5px 5px;
    box-shadow: 0 1px 3px 0 #b6b6b6;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;

    .history-icon {
      margin-right: 12px;
    }

    > li:first-child {
      padding: 0 16px;
    }

    > li:not(:first-child) {
      display: flex;
      align-items: center;
      padding: 8px 16px;

      &:hover {
        background-color: #ddd;
        cursor: pointer;
      }

      > ion-icon {
        margin: 0 10px 0 0;
      }

      > label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .search-result {
    padding: 0 16px 16px;
  }
}
