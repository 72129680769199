.facebook-sign-in-button {
  --background: #4968AD;
  height: 40px;
  text-align: center;
  margin-bottom: 16px;
  --padding-end: 30px;

  .facebook-sign-in-button__icon {
    font-size: 32px;
  }

  .facebook-sign-in-button__text {
    width: 100%;
  }
  a {
    color: white;
    text-decoration: none;
  }
  a :visited {
    color: white;
    text-decoration: none;
  }
}
