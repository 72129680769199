.sign-up-page-core-layout {
  .sign-up-page {
    --padding-top: 6px;
    --padding-bottom: 6px;
    --padding-start: 6px;
    --padding-end: 6px;

    h1,
    p {
      margin: 0;
    }
    background-color: #f7f7f7;

    .sign-up-header-section {
      align-items: center;
      align-items: center;
      .header-text {
        margin-right: 4rem;
      }
      margin-bottom: 14px;
    }

    .sign-up-send-otp-form {
      margin-bottom: 20px;
    }

    .line-login-button {
      --background: #00c300;
      --background-activated: #00b300;
      --background-focused: #00e000;
      --background-hover: #00e000;
      --padding-start: 0px;
      --padding-end: 30px;

      height: 40px;
      margin-bottom: 12px;

      .line-login-button__icon {
        height: 100%;
        border-right: 1px solid #00b300;
      }

      &:hover .line-login-button__icon {
        border-right: 1px solid #00c900;
      }

      .line-login-button__text {
        width: 100%;
      }
    }

    .facebook-sign-in-button {
      --background: #4968ad;
      height: 40px;
      text-align: center;
      margin-bottom: 12px;
      --padding-end: 30px;

      .facebook-sign-in-button__icon {
        font-size: 32px;
      }

      .facebook-sign-in-button__text {
        width: 100%;
      }
    }

    .apple-sign-in-button {
      --border-radius: 4px;
      --border-width: 1px;
      --border-style: solid;
      --border-color: black;
      --padding-start: 0;
      --padding-end: 30px;
      --background-hover: rgb(70, 67, 67);

      height: 40px;
      text-align: center;
      margin-bottom: 12px;
    }

    .sign-up-sign-in-row {
      display: flex;
      justify-content: center;
    }
    .blue-underline {
      cursor: pointer;
      color: #2d95f4;
      text-decoration: underline;
    }

    .bold {
      font-weight: 600;
    }
  }
  .policy-paragraph {
    text-align: center;
    margin-bottom: 8px;
  }
}
