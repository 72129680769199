.job-history-report-pane {
  background-color: white;
  padding: 16px 16px 16px 0;
}

.job-image-answer {
  width: calc((100% - 24px) / 3);
  height: 100px;
  display: inline-block;
  margin-top: 8px;
  border-radius: 6px;
  background-color: var(--ion-color-light);

  & > * {
    border-radius: 5px;
  }
}

.job-images-answer-container {
  & > *:nth-child(3n+2) {
    margin-left: 12px;
    margin-right: 12px;
  }
}
