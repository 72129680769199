.announcement-container {
  margin-top: 38px;
  margin-bottom: 52px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  align-items: center;
  display: flex !important;
  height: 143px;

  .announcement-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    align-items: center;
    display: flex !important;
    height: 143px;
    box-shadow: 1px 2px 4px 0 #B6B6B6;
    border-radius: 6px;
    overflow: auto;
  }
}

.swiper-pagination-bullet-active {
  background: #2D95F4 !important;
}

.swiper-pagination-bullet {
  background: #545454;
}

.swiper-slide-active {
  .announcement-img {
    margin-top: 16px;
    margin-bottom: 26px;
    height: 175px;
  }
}

.slide-container {
  background-color: white;
}

.address-fab {
  width: 100%;
  padding: 0.5rem 1rem;
  
  .address-item {
    --color: var(--ion-color-primary);
    --background: #F4F4F4;
    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #8E8E93;
    overflow: initial;

    .address-item-name-container {
      margin-right: auto;
      padding: 8px 0;
    }

    .address-label-container {
      padding: 0;
    }

    .address-update-button {
      --background: none;
      --background-activated: none;
      --background-focused: none;
      --background-hover: none;
      height: 100%;
    }

    .address-update-icon {
      color: black;
      font-size: 40px;
    }

    .label-text {
      color: #2D95F4;
      font-size: 16px;
      line-height: 37px;
    }

    .label-icon {
      height: 25px;
      padding-right: 6px;
    }

    .update-label-container {
      position: absolute;
      top: -20px;
      right: 3px;
      z-index: 10;
      padding: 2px 12px;
      background-color: #1B4170;
      color: #ffffff;
      font-size: 16px;
      line-height: 22px;
    }

    .arrow-down {
      width: 0; 
      height: 0; 
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #1B4170;
      position: absolute;
      top: 6px;
      right: 32px;
    }
  }
}

.filter-btn-container {
  font-size: 14px !important;
  max-height: 33px !important;
  max-width: 100% !important;
  padding: 2px 0 !important;
  --color: #47525D;
  --color-activated: #47525D;
  --color-focused: #47525D;
  --color-hover: #47525D;
  --padding-start: 6px;
  --padding-end: 6px;
  --border-color: #47525D;
  --background: none;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;

  &.main {
    float: left;
  }
}

.check-icon-column {
  margin: 2px 10px 0 1px;

  .check-icon {
    width: 24px;
    height: 24px;
  }
}

.filter-distance-lead-container {
  --ion-grid-padding: 0;

  ion-col {
    --ion-grid-column-padding: 0;
  }

  .row-flex-end {
    justify-content: flex-end;
  }
}

.filter-lead-row-first,
.filter-lead-row {
  padding: 24px;
}

.filter-lead-row-first {
  border-bottom: 1px solid #DDDDDD;
}

.filter-btn-icon {
  font-size: 18px !important;
  padding-right: 5px;
}

.modal-distance-filter-container {
  --height: 153px;
  align-items: flex-end;
}

img.filter-btn-icon {
  height: 13px;
}

.filter-lead-container {
  background: white;
  padding: 0 16px 10px 16px;

  ion-col.ios.hydrated {
    padding: 0 !important;
    text-align: right !important;
  }

  .search-result {
    text-align: left !important;
  }
}

.filter-label-container {
  display: flex;
  background-color: #D8D8D8;
  border-radius: 4px;
  padding: 4px 8px;
  color: #47525D;
  font-size: 14px;
  align-items: center;

  .arrow-btn-icon {
    padding: 0 5px;
  }

  .cancel-icon-container {
    margin-left: auto;
    align-items: center;
    display: flex;
    font-size: 17px;
  }

  .filter-label {
    display: flex;
    align-items: center;
  }
}

.filter-label-container-status {
  margin-top: 8px;
  display: flex;
  background-color: #D8D8D8;
  border-radius: 4px;
  padding: 4px 8px;
  color: #47525D;
  font-size: 14px;
  align-items: center;

  .arrow-btn-icon {
    padding: 0 5px;
  }

  .cancel-icon-container {
    margin-left: auto;
    align-items: center;
    display: flex;
    font-size: 17px;
  }

  .filter-label {
    display: flex;
    align-items: center;
  }
}

.row-filter-container {
  align-items: center !important;
}
